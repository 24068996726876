<script lang="ts">
    import type { Element } from '../types'
    import Flex from './Flex.svelte'

    let classNames = ''
    export { classNames as class }
    export let element: Element = 'div'
    export let justifyContent:
        | 'around'
        | 'between'
        | 'end'
        | 'evenly'
        | 'start'
        | 'center' = 'start'
</script>

<Flex
    --gap-x="0"
    --gap-y="0"
    class="row {classNames}"
    {element}
    {justifyContent}
    {...$$restProps}
>
    <slot />
</Flex>

<style global lang="postcss">
    .row {
        margin: 0 -0.75rem;
    }

    .row > .col {
        padding: 0 0.75rem;
    }
</style>
